import React from 'react'
import { Link } from 'react-router-dom'

// Components:
import BreadcrumbBar from '../../common/BreadcrumbBar'
import CheckBox from '../../common/Forms/CheckBox'
import DropDown from '../../common/Forms/DropDown'
import ErrorList from '../../common/ErrorList'
import FieldList from '../../common/Forms/FieldList'
import FormField from '../../common/Forms/FormField'
import FormFooter from '../../common/Forms/FormFooter'
import FormHeader from '../../common/Forms/FormHeader'
import FormSection from '../../common/Forms/FormSection'
import PleaseWait from '../../common/PleaseWait'
import TabContent from '../../common/Tabs/TabContent'
import TabGroup from '../../common/Tabs/TabGroup'
// APIs:
import TrailerTypeApi from '../../apis/movements/TrailerTypeApi'

// This is the "edit" page for a specific trailer type.
class TrailerTypeForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      id: this.props.match.params.id || '',
      loading: true,
      errors: []
    }
  }

  componentDidMount () {
    if (!this.state.id) { return this.initCreate() }

    TrailerTypeApi.show(this.state.id).then(this.initUpdate, this.handleErrors)
  }

  initCreate = () => {
    document.title = 'New Trailer Type'

    this.setState({
      id: null,
      name: '',
      trailersAttached: '',
      vehicleCategory: '',
      grossWeightKg: '',
      lengthM: '',
      widthM: '',
      heightM: '',
      loadingMetres: '',
      rigidSided: false,
      refrigerated: false,
      loading: false,
      errors: []
    })
  }

  initUpdate = (json) => {
    document.title = `Editing ${json.name}`

    this.setState({
      id: json.id,
      name: json.name || '',
      trailersAttached: json.trailersAttached || '',
      vehicleCategory: json.vehicleCategory || '',
      grossWeightKg: json.grossWeightKg || '',
      lengthM: json.lengthM || '',
      widthM: json.widthM || '',
      heightM: json.heightM || '',
      loadingMetres: json.loadingMetres || '',
      rigidSided: json.rigidSided || false,
      refrigerated: json.refrigerated || false,
      loading: false,
      errors: []
    })
  }

  handleNameChange = (event) => {
    this.setState({ name: event.target.value })
  }

  handleTrailersAttachedChange = (event) => {
    this.setState({ trailersAttached: event.target.value })
  }

  handleVehicleCategoryChange = (event) => {
    this.setState({ vehicleCategory: event.target.value })
  }

  handleGrossWeightKgChange = (event) => {
    this.setState({ grossWeightKg: event.target.value })
  }

  handleLengthChange = (event) => {
    this.setState({ lengthM: event.target.value })
  }

  handleWidthChange = (event) => {
    this.setState({ widthM: event.target.value })
  }

  handleHeightChange = (event) => {
    this.setState({ heightM: event.target.value })
  }

  handleLoadingMetresChange = (event) => {
    this.setState({ loadingMetres: event.target.value })
  }

  handleRigidSidedChange = () => {
    this.setState({ rigidSided: !this.state.rigidSided })
  }

  handleRefrigeratedChange = () => {
    this.setState({ refrigerated: !this.state.refrigerated })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const id = this.state.id
    const params = { trailerType: this.state }

    if (this.state.id) {
      TrailerTypeApi.update(id, params).then(this.handleSuccess, this.handleErrors)
    } else {
      TrailerTypeApi.create(params).then(this.handleSuccess, this.handleErrors)
    }
  }

  handleSuccess = (json) => {
    this.props.history.push(`/reactive/movements/trailer_types/${json.id}`)
  }

  handleErrors = (json) => {
    this.setState({ loading: false, errors: json.errors || [json.error] })
  }

  render () {
    if (this.state.loading) { return <PleaseWait /> }

    const breadcrumbs = {
      '/reactive/movements/trailer_types': 'Trailer Types',
      '/reactive/movements/trailer_types/new': 'Edit'
    }

    let cancelUrl = '/reactive/movements/trailer_types'
    if (this.state.id) { cancelUrl = `/reactive/movements/trailer_types/${this.state.id}` }

    const generalHeader = (
      <FormHeader title='General'>
        <label>
          Rigid Sided?
          <CheckBox value={this.state.rigidSided} onChange={this.handleRigidSidedChange} />
        </label>
        <label>
          Refrigerated?
          <CheckBox value={this.state.refrigerated} onChange={this.handleRefrigeratedChange} />
        </label>
      </FormHeader>
    )

    return (
      <div>
        <BreadcrumbBar route={breadcrumbs} />
        <h3>{document.title}</h3>
        <ErrorList errors={this.state.errors} />
        <TabGroup labels={['General']}>
          <TabContent>
            <FormSection title={generalHeader}>
              <FieldList>
                <FormField label='Name'>
                  <input
                    className='form-control'
                    value={this.state.name}
                    onChange={this.handleNameChange}
                    autoFocus={true}
                  />
                </FormField>
                <FormField label='Category'>
                  <DropDown
                    source={TrailerTypeApi.vehicleCategories}
                    value={this.state.vehicleCategory}
                    onChange={this.handleVehicleCategoryChange}
                  />
                </FormField>
              </FieldList>
              <FieldList>
                <FormField label='Trailers'>
                  <DropDown
                    source={TrailerTypeApi.trailerOptions}
                    value={this.state.trailersAttached}
                    onChange={this.handleTrailersAttachedChange}
                  />
                </FormField>
                <FormField label='Capacity'>
                  <div className='input-group'>
                    <input
                      className='form-control'
                      type='number' min='0'
                      value={this.state.grossWeightKg}
                      onChange={this.handleGrossWeightKgChange}
                    />
                    <span className='input-group-addon'>kg</span>
                  </div>
                </FormField>
              </FieldList>
              <FieldList>
                <FormField label='Dimensions'>
                  <div className='input-group'>
                    <input
                      className='form-control'
                      type='number' min='0'
                      value={this.state.lengthM}
                      onChange={this.handleLengthChange}
                    />
                    <span className='input-group-addon'>×</span>
                    <input
                      className='form-control'
                      type='number' min='0'
                      value={this.state.widthM}
                      onChange={this.handleWidthChange}
                    />
                    <span className='input-group-addon'>×</span>
                    <input
                      className='form-control'
                      type='number' min='0'
                      value={this.state.heightM}
                      onChange={this.handleHeightChange}
                    />
                    <span className='input-group-addon'>m</span>
                  </div>
                </FormField>
                <FormField label='Loading Metres'>
                  <div className='input-group'>
                    <input
                      className='form-control'
                      type='number' min='0' step='0.1'
                      value={this.state.loadingMetres}
                      onChange={this.handleLoadingMetresChange}
                    />
                    <span className='input-group-addon'>m</span>
                  </div>
                </FormField>
              </FieldList>
            </FormSection>
          </TabContent>
        </TabGroup>
        <FormFooter>
          <button className='btn btn-primary' onClick={this.handleSubmit}>
            Save Changes
          </button>
          &nbsp;
          <Link className='btn btn-labeled' to={cancelUrl}>
            <i className='btn-label fa fa-times' />
            Cancel
          </Link>
        </FormFooter>
      </div>
    )
  }
}

export default TrailerTypeForm

import React, { Component } from 'react'
import moment from 'moment'

import StateManager from './StateManager'

import Cell from './unit/Cell'
import Icon from './unit/Icon'
import Notes from './unit/Notes'
import Controls from './unit/Controls'
import Problems from './unit/Problems'
import DimensionsLines, { DIMENSIONS_LINES_PROPS } from './unit/DimensionsLines'

// Please Refactor me. Every cell should probably be a distinct component.

export const CONSIGNMENT_FLAGS = [
  "single_load",
  "do_not_stack",
  "do_not_transship",
  "waste",
  "foodstuffs",
  "temperature_controlled",
  "overlength",
  "must_load",
  "tall_packages",
  "tail_lift_on_collection",
  "tail_lift_on_delivery"
]

const DATE_FIELDS = [
  "ready_date",
  "delivery_required_by",
  "collection_date",
  "due_in_date"
]

const BLANK_DATE = { primary: '--/--' }

class Link extends Component {
  render() {
    return <a
      href={`/${this.props.type}/${this.props.id}`}
      target="_blank"
      title={this.props.title || this.props.text}
      style={this.props.style || null}
      onClick={e => e.stopPropagation()}
    >{this.props.text}</a>
  }
}

export default class Unit extends Component {

  constructor(props) {
    super(props)
    this.manager = new StateManager()
    return this
  }

  onClick(e) {
    // https://www.w3schools.com/jsref/event_ctrlkey.asp
    // CTRL did not seem to work on Mac
    this._toggleSelected(e.ctrlKey)
  }

  onDragStart(e) {
    // https://www.w3schools.com/html/html5_draganddrop.asp
    // We have to attach selectedUnits data to support multi-window drops
    // We can't attach JS objects, so need to convert to string
    e.dataTransfer.effectAllowed = "move"
    e.dataTransfer.setData("unit-id", this.props.id)
    var selected = this.manager.selectedUnits()
    window.selected = selected // For multiwindow support
    e.dataTransfer.setData("selected-ids", selected.join(","))
    console.log(`Unit ${this.props.id} onDragStart`)
    window.hideAllPopovers()
  }

  onDragEnd(e) {
    // If not move, the drop failed- eg: drop off screen to non-browser window
    if (e.dataTransfer.dropEffect != 'move') { return }
    // Convert the selected-ids back to original format
    this.manager.multiWindowDrop(this.props.id, window.selected)
    this.manager.setDropTarget(null)
  }

  _toggleSelected(multi) {
    this.setState({ selected: this.manager.toggleSelected(this.props.id, multi) })
  }

  _getUpdateStatus() {
    return this.manager.getUpdateStatus(this.props.id)
  }

  _getSelected() {
    if (this.manager.getSelected(this.props.id)) { return 'selected' }
  }

  _css() {
    return [
      'unit',
      this._getSelected(),
      this._getUpdateStatus(),
    ].compact().join(' ')
  }

  _consignment_flags() {
    let flags = {}
    CONSIGNMENT_FLAGS.select(f => this.props[f]).each(f => flags[f] = this.props[f])
    return flags
  }

  _collection() {
    return {
      type:    'collection',
      unit_id: this.props.id
    }
  }

  _delivery() {
    return {
      type:     'delivery',
      unit_id: this.props.id
    }
  }

  _isUnassigned() {
    return this.props.block_id == this.props.status
  }

  _alwaysShow() {
    return this.props.show_on_planner == 'always' && this._isUnassigned()
  }

  _name() {
    let props = {
      id:   this.props.job_id,
      type: 'jobs',
      text: this.props.name
    }
    if (this._alwaysShow()) {
      props.title = `${this.props.name} (always show on planner)`
      props.style = { fontWeight: "bold" }
    }
    let data = { primary: <Link {...props} /> }
    if (this.columns.excludes('customer')) {
      data.secondary = this._customer().primary
    }
    return data
  }

  _customer() {
    if (!this.props.customer_id) { return {} }
    return {
      primary: <Link type="entities" id={this.props.customer_id} text={this.props.customer} />
    }
  }

  _shipper() {
    let data = {}
    if (this.props.shipper_id) {
      data.primary = <Link type="entities" id={this.props.shipper_id} text={this.props.shipper} />
    }
    if (this.columns.excludes('shipping_region')) {
      data.secondary = this.props.shipping_region
    }
    return data
  }

  _consignee() {
    let data = {}
    if (this.props.consignee_id) {
      data.primary = <Link
        type="entities"
        id={this.props.consignee_id}
        text={this.props.consignee}
      />
    }
    if (this.columns.excludes('consignee_region')) {
      data.secondary = this.props.consignee_region
    }
    return data
  }

  _hubBox(hub) {
    return {
      primary: <span>{hub || ''}</span>
    }
  }

  _origin_hub() {
    return this._hubBox(this.props.origin_hub)
  }

  _destination_hub() {
    return this._hubBox(this.props.destination_hub)
  }

  _cubic_metres() {
    return {
      primary: <DimensionsLines {...this.props.only(DIMENSIONS_LINES_PROPS)}>
        {this.props.cubic_metres ? `${this.props.cubic_metres} m³` : '???'}
      </DimensionsLines>
    }
  }

  _notes_status() {
    return {
      primary: new Notes({
        id:             this.props.id,
        job:            this.props.job_notes,
        customer:       this.props.customer_notes,
        address_status: this.props.address_status,
      }).render()
    }
  }

  _loading_metres() {
    return {
      primary: `${this.props.loading_metres || '0.0'} m`
    }
  }

  _gross_weight_kg() {
    return {
      primary: `${this.props.gross_weight_kg || 0} kg`
    }
  }

  _customs_status() {
    const tad = this.props.tad_requirement?.replace('_', ' ') || 'standard'
    const customsSummary = `${this.props.customs_mrn || 'No MRN'} (TAD: ${tad})`

    return { text: this.props.customs_status, title: customsSummary }
  }

  _defaultColumn(name) {
    return { primary: this.props[name] }
  }

  _dateField(name) {
    if (!this.props[name]) { return BLANK_DATE }
    return {
      primary: moment(this.props[name]).format("DD/MM")
    }
  }

  _columnValues(name) {
    if (DATE_FIELDS.includes(name)) { return this._dateField(name) }
    let special = this[`_${name}`]
    return special ? special.bind(this)() : this._defaultColumn(name)
  }

  _isWholeJob() {
    return this.props.name.excludes('(') // '... (x of y)'
  }

  _problems() {
    // This should be a react component.. Need to get on top of state management
    return {
      primary: new Problems({
        problems:     this.props.problems,
        id:           this.props.id
      }).render()
    }
  }

  _isOffGroupage() {
    let booking_groupage_id = this.manager.getBookingGroupageId(this.props.block_id)
    return booking_groupage_id && this.props.groupage_id != booking_groupage_id
  }

  _icon() {
    // This should be a react component.. Need to get on top of state management
    return new Icon({
      id:           this.props.id,
      is_whole_job: this._isWholeJob(),
      off_groupage: this._isOffGroupage()
    }).render()
  }

  render() {
    this.columns = this.manager.visibleColumns()
    let html_props = {
      id:          `unit-${this.props.id}`,
      className:   this._css(),
      onDragStart: this.onDragStart.bind(this),
      draggable:   true
    }
    return(
      <tr {...html_props} key={this.props.id} onClick={this.onClick.bind(this)}
        onDragEnd={this.onDragEnd.bind(this)} >
        {this._icon()}
        {this.columns.map(
          (v, i) => <Cell
            width={this.manager.columnWidth(v)}
            name={v}
            content={this._columnValues(v)}
            key={i}
          />
        )}
        <Controls {...this.manager.unitControlProps(this.props.id)} />
      </tr>
    )
  }
}
